import React from 'react';
import BaseLayout from '../layouts/Base';
import ContactInfo from '../components/layout/Contact/ContactInfo';

interface Props {}

const Contact = (props: Props) => {
  return (
    <BaseLayout
      title='Contact'
      subtitle='Reach out to us if you have any inquiries or encounter any issues. Expect a response to your associated login email address within 24 hours. Please view our FAQ page to see if a similar inquiry has already been answered.'
      hasHero={true}
      backgroundImage='https://i.pinimg.com/originals/b8/4f/41/b84f4195a6d33c93d01111287c0c7b7e.jpg'
    >
      <ContactInfo />
    </BaseLayout>
  );
};

export default Contact;
