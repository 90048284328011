import { Box, Typography, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';

const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'name',
    headerName: 'Name',
    width: 120,
  },
  {
    field: 'description',
    headerName: 'Description',
    width: 500,
  },
  {
    field: 'specifications',
    headerName: 'specifications',
    width: 200,
  },
  {
    field: 'category',
    headerName: 'Category',
    width: 110,
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 110,
  },
  {
    field: 'status',
    headerName: 'Status',
    renderCell: (cell) => {
      return (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '2rem',
            margin: '0 auto',
            borderRadius: '0.3rem',
            backgroundColor:
              cell.value === 'Checked Out' ? '#892a24' : '#08633b',
          }}
        >
          <Typography
            sx={{
              color:
                cell.value === 'Checked Out'
                  ? 'rgba(255, 145, 140, 1)'
                  : '#43d193',
            }}
            variant='string'
          >
            {cell.value}
          </Typography>
        </Box>
      );
    },
    width: 110,
  },
  {
    field: 'date_added',
    headerName: 'Date added',
    width: 150,
  },
  {
    field: 'actions',
    headerName: 'Actions',
    renderCell: (cellValues) => {
      return (
        <>
          <Link to={`/admin/devices/edit/${cellValues.row.id}`}>
            <Button>
              <EditIcon />
            </Button>
          </Link>
          <Link to={`/admin/devices/delete/${cellValues.row.id}`}>
            <Button onClick={() => {}}>
              <DeleteIcon />
            </Button>
          </Link>
        </>
      );
    },
    width: 150,
  },
];

export default columns;
