import Button from './Button';

type HeroProps = {
  $title?: string;
  $subtitle?: string;
  $hasBackground?: boolean;
  backgroundImage?: string;
  hasButton?: boolean;
  buttonText?: string;
  buttonLink?: string;
};

const styles = {
  container:
    'bg-cover bg-center bg-no-repeat flex flex-col items-center justify-center w-full',
  content:
    'max-w-screen-xl text-center mx-auto px-4 py-16 md:py-24 lg:py-32 xl:py-40',
  title: 'text-4xl md:text-5xl font-bold leading-tight text-white',
  subtitle: 'text-xl font-semibold leading-tight my-8 text-white',
};

const Hero: React.FC<HeroProps> = ({
  $title,
  $subtitle,
  $hasBackground,
  backgroundImage,
  hasButton,
  buttonText,
}) => {
  const onClick = () => {};

  return (
    <section
      className={`${styles.container} ${
        $hasBackground ? 'bg-none' : 'bg-gray-200'
      }`}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className={styles.content}>
        <h1 className={styles.title}>{$title}</h1>
        <p className={styles.subtitle}>{$subtitle}</p>
        {hasButton && (
          <Button
            children={buttonText}
            buttonType='primary'
            onClick={onClick}
          />
        )}
      </div>
    </section>
  );
};

export default Hero;
