import { Link } from "react-router-dom";

type CardProps = {
  image?: string;
  title: string;
  link?: string;
  category?: string;
  type?: string;
  specifications?: string[];
};

const styles = {
  base: "flex flex-col h-full overflow-hidden transition-all ease-in-out duration-500 transform hover:-translate-y-1 hover:scale-105 cursor-pointer",
  title: "font-bold ml-8 mt-4 text-xl",
  type: "uppercase block text-white w-max ml-8 mt-4 bg-indigo-700 p-1",
  text: "text-xl",
  image: "w-64 h-64",
};

// TODO: Add link with react-router-dom to route to slug

const Card = ({ image, title, link, category, type }: CardProps) => {
  return (
    <Link
      to={{
        pathname: link,
        state: {
          title,
        },
      }}
    >
      <div className={styles.base}>
        <img
          src={`${process.env.PUBLIC_URL}/images/device/${image}`}
          alt={title}
        />
        <div className="flex flex-col">
          <p className={styles.type}>{type}</p>
          <p className={styles.title}>{title}</p>
          <p>
            <span className={styles.title}>Category: </span>
            <span className={styles.text}>{category}</span>
          </p>
        </div>
      </div>
    </Link>
  );
};

export default Card;
