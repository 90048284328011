import BaseLayout from "../layouts/Base";
import Container from "../components/layout/Container";
import { useQuery } from "@apollo/client";
import Interests from "../components/layout/Profile/Interests";
import * as queries from "../queries";
import ProfileInfo from "../components/layout/Profile/ProfileInfo";
import { useUser } from "../contexts/AuthenticatedContext";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

interface Props {}

const Profile = (props: Props) => {
  const userContext = useUser();
  const history = useHistory();

  // useEffect(() => {
  //   if (!userContext?.state.id) {
  //     history.push("/");
  //   }
  // }, [history, userContext]);

  const { loading, error, data } = useQuery(queries.GET_USER_BY_ID, {
    variables: { id: userContext?.state.id },
  });

  if (error) return <p>Error :</p>;

  let role;
  if (!loading) {
    switch (data.getUser.role.id) {
      case 1:
        role = "Super Admin";
        break;
      case 2:
        role = "Admin";
        break;
      case 3:
        role = "Student";
        break;
      case 4:
        role = "Lecturer";
        break;
    }
  }
  return (
    <BaseLayout
      title={data && `${data.getUser.firstname} ${data.getUser.lastname}`}
      subtitle={data && `${userContext?.state.email} (${role}) `}
      hasHero={true}
      backgroundImage="https://i.pinimg.com/originals/b8/4f/41/b84f4195a6d33c93d01111287c0c7b7e.jpg"
    >
      <Container>
        <ProfileInfo userId={userContext?.state.id} />
        <Interests />
      </Container>
    </BaseLayout>
  );
};

export default Profile;
