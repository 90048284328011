import { useState } from "react";
import SearchField from "../../../components/layout/SearchField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Formik } from "formik";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import * as queries from "../../../queries";
import { TextField, Button } from "@mui/material";

interface Props {}

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  description: yup.string().required("Description is required"),
});

const styles = {
  flex: "flex items-center justify-between",
};

const AddType = (props: Props) => {
  const [open, setOpen] = useState(false);

  const [addDevice, { loading, error }] = useMutation(queries.ADD_TYPE);
  if (loading) return <p>Submitting</p>;
  if (error) return <p>Submission error! {error.message}</p>;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={styles.flex}>
      <SearchField />
      <Button variant="contained" onClick={handleClickOpen}>
        Add Item
      </Button>
      <Dialog open={open} onClose={handleClose} keepMounted>
        <DialogTitle>Add Type</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add a new type, please fill out the form below.
          </DialogContentText>
          <div>
            <Formik
              initialValues={{
                name: "",
                description: "",
                icon_url: "http://lorempixel.com/640/480",
              }}
              onSubmit={async (data, { setSubmitting }) => {
                setSubmitting(true);

                addDevice({
                  variables: {
                    name: data.name,
                    description: data.description,
                  },
                  refetchQueries: [
                    {
                      query: queries.TYPES,
                    },
                  ],
                });

                handleClose();

                setSubmitting(false);
              }}
              validationSchema={validationSchema}
            >
              {({
                values,
                isSubmitting,
                handleBlur,
                handleSubmit,
                handleChange,
                errors,
              }) => (
                <form className="mt-4 grid gap-y-4" onSubmit={handleSubmit}>
                  <TextField
                    type="text"
                    name="name"
                    placeholder="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    helperText={errors.name}
                    error={Boolean(errors.name)}
                  />
                  <TextField
                    type="text"
                    name="description"
                    placeholder="Description"
                    onBlur={handleBlur}
                    multiline
                    rows={4}
                    onChange={handleChange}
                    helperText={errors.description}
                    error={Boolean(errors.description)}
                  />
                  <DialogActions>
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      Add Type
                    </Button>
                  </DialogActions>
                </form>
              )}
            </Formik>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddType;
