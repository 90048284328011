import { Formik, Field } from "formik";
import Button from "../Button";
import InputError from "../FormComponents/InputError";
import * as yup from "yup";
import tw from "tailwind-styled-components";
// import AlternativeSignUp from './AlternativeSignUp';
import { useUser } from "../../../contexts/AuthenticatedContext";
import { Link, useHistory } from "react-router-dom";
import { Snackbar } from "@mui/material";

const validationSchema = yup.object({
  email: yup
    .string()
    .email()
    .max(128)
    .required("Please enter your e-mail address"),
  password: yup.string().required("Please enter your password"),
});

interface Props {}

const SForm = tw.form`
  grid
  gap-y-8
  mt-8
`;

const SubmitButton = tw.div`
  text-center
`;

const Login = (props: Props) => {
  const handleUserContext = useUser();
  const history = useHistory();

  const handleLoginContext = ({
    email,
    id,
    roleId,
  }: {
    email: string;
    id: number;
    roleId: number;
  }) => {
    handleUserContext!.dispatch({
      type: "setUser",
      payload: { email: email, id: id, roleId: roleId },
    });
    return history.push("/");
  };

  return (
    <div className="max-w-lg mx-auto bg-white shadow-xl rounded-lg p-4">
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);

          // TODO: change to path defined in .env or config file
          const request = await fetch(
            "https://belgian-waffles-server.herokuapp.com/login",
            {
              method: "POST",
              credentials: "include",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
          );
          const response = await request.json();

          if (response.statusCode === 401) {
            // TODO: Handle error code (unauthorized request == wrong password/username combination);
            return;
          }
          // Correct login assumed
          handleLoginContext(response);

          setSubmitting(false);
        }}
        validationSchema={validationSchema}
      >
        {({ values, isSubmitting, handleBlur, handleSubmit, handleChange }) => (
          <SForm onSubmit={handleSubmit}>
            <Field
              type="email"
              as={InputError}
              name="email"
              placeholder="Email Address"
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <Field
              type="password"
              as={InputError}
              name="password"
              placeholder="Password"
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <SubmitButton>
              <Button
                type="submit"
                $fullWidth
                buttonType="primary"
                disabled={isSubmitting}
                onClick={() => {}}
              >
                Sign in
              </Button>
            </SubmitButton>
          </SForm>
        )}
      </Formik>
      <Link to="/auth/register">
        <p className="text-center mt-4 text-indigo-500">
          Don't have an account yet?
        </p>
      </Link>
    </div>
  );
};

export default Login;
