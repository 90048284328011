import { gql } from '@apollo/client';

export const CATEGORIES = gql`
  query {
    categories {
      id
      name
      description
    }
  }
`;

export const TYPES = gql`
  query {
    types {
      id
      name
      description
    }
  }
`;

export const DEVICES = gql`
  query {
    devices {
      id
      name
      description
      specifications
      qr_code
      picture_url
      userId
      date_created
      is_available
      category {
        name
      }
      type {
        name
      }
    }
  }
`;

export const GET_DEVICE = gql`
  query ($id: Int!) {
    getDevice(id: $id) {
      id
      name
      description
      specifications
      qr_code
      picture_url
      date_created
      is_available
      category {
        id
        name
      }
      type {
        id
        name
      }
      user {
        id
        firstname
        lastname
      }
    }
  }
`;

export const ADD_CATEGORY = gql`
  mutation ($name: String!, $description: String!, $icon_url: String!) {
    createCategory(
      createCategoryInput: {
        name: $name
        description: $description
        icon_url: $icon_url
      }
    ) {
      id
    }
  }
`;

export const ADD_TYPE = gql`
  mutation ($name: String!, $description: String!) {
    createType(createTypeInput: { name: $name, description: $description }) {
      id
    }
  }
`;

export const ADD_DEVICE = gql`
  mutation (
    $name: String!
    $specifications: [String!]
    $typeId: Int!
    $is_available: Boolean!
    $qr_code: String!
    $description: String!
    $categoryId: Int!
    $userId: Int!
  ) {
    createDevice(
      createDeviceInput: {
        name: $name
        typeId: $typeId
        is_available: $is_available
        description: $description
        userId: $userId
        categoryId: $categoryId
        qr_code: $qr_code
        specifications: $specifications
      }
    ) {
      id
    }
  }
`;

export const NON_INSTOCK_DEVICES = gql`
  {
    deviceStatus(is_available: false) {
      id
      name
      description
      date_created
      category {
        name
      }
      type {
        name
      }
    }
  }
`;

export const INSTOCK_DEVICES = gql`
  {
    deviceStatus(is_available: true) {
      id
      name
      description
      date_created
      category {
        name
      }
      type {
        name
      }
    }
  }
`;

export const ADMINISTRATORS = gql`
  {
    getByRole(roleId: 2) {
      id
      firstname
      lastname
      email
      role {
        id
      }
    }
  }
`;

export const USERS = gql`
  query {
    users {
      id
      firstname
      lastname
      email
      role {
        id
      }
    }
  }
`;

export const LECTURERS = gql`
  {
    getByRole(roleId: 4) {
      id
      firstname
      lastname
      email
      role {
        id
      }
    }
  }
`;

export const STUDENTS = gql`
  {
    getByRole(roleId: 3) {
      id
      firstname
      lastname
      email
      role {
        id
      }
    }
  }
`;

export const SUPER_ADMINS = gql`
  {
    getByRole(roleId: 1) {
      id
      firstname
      lastname
      email
      role {
        id
      }
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation (
    $id: Int!
    $name: String!
    $description: String!
    $icon_url: String!
  ) {
    updateCategory(
      id: $id
      input: { name: $name, description: $description, icon_url: $icon_url }
    ) {
      id
    }
  }
`;

export const UPDATE_TYPE = gql`
  mutation ($id: Int!, $name: String!, $description: String!) {
    updateType(id: $id, input: { name: $name, description: $description }) {
      id
    }
  }
`;

export const UPDATE_DEVICE = gql`
  mutation (
    $id: Int!
    $name: String!
    $typeId: Int!
    $categoryId: Int!
    $is_available: Boolean!
    $description: String!
    $specifications: [String!]
    $userId: Int!
    $qr_code: String!
  ) {
    updateDevice(
      id: $id
      input: {
        name: $name
        typeId: $typeId
        categoryId: $categoryId
        is_available: $is_available
        description: $description
        specifications: $specifications
        qr_code: $qr_code
        userId: $userId
      }
    ) {
      id
    }
  }
`;

export const DELETE_DEVICE = gql`
  mutation ($id: Int!) {
    deleteDevice(id: $id) {
      id
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation ($id: Int!) {
    deleteCategory(id: $id) {
      id
    }
  }
`;

export const DELETE_TYPE = gql`
  mutation ($id: Int!) {
    deleteType(id: $id) {
      id
    }
  }
`;

export const GET_CATEGORY_BY_ID = gql`
  query ($id: Int!) {
    getCategory(id: $id) {
      id
      name
      description
    }
  }
`;

export const GET_TYPE_BY_ID = gql`
  query ($id: Int!) {
    getType(id: $id) {
      id
      name
      description
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query ($id: Int!) {
    getUser(id: $id) {
      id
      firstname
      lastname
      devices {
        id
        name
        description
        picture_url
      }
      email
      role {
        id
      }
    }
  }
`;

export const GET_DEVICE_BY_ID = gql`
  query ($id: Int!) {
    getDevice(id: $id) {
      id
      name
      picture_url
    }
  }
`;

export const GET_USER_DEVICES = gql`
  query ($id: Int!) {
    getUser(id: $id) {
      devices {
        id
        name
        picture_url
        type {
          name
        }
        category {
          name
        }
      }
    }
  }
`;
