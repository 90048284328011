import React from 'react';

interface Props {}

const Cart = (props: Props) => {
  return (
    <div>
      <h1>Cart</h1>
    </div>
  );
};

export default Cart;
