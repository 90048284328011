import React from 'react';
import TextField from '@mui/material/TextField';

interface Props {}

const SearchField = (props: Props) => {
  return <TextField id='outlined-basic' label='Search...' variant='outlined' />;
};

export default SearchField;
