type GridProps = {
  children: React.ReactNode;
};

const styles = {
  base: "grid gap-y-8 sm:grid-cols-1 md:grid-cols-2 md:gap-4 lg:grid-cols-3 lg:gap-8",
};

const Grid = ({ children }: GridProps) => {
  return <div className={styles.base}>{children}</div>;
};

export default Grid;
