import React from "react";
import { useField } from "formik";
import Textarea, { InputProps } from "./Textarea";
import tw from "tailwind-styled-components";

const Error = tw.div`
  text-red-500
  font-bold
  text-md
`;

const InputError = (props: InputProps) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <React.Fragment>
      <Textarea
        {...field}
        placeholder={props.placeholder}
        value={props.value}
        type={props.type}
      />
      {errorText && <Error>{errorText}</Error>}
    </React.Fragment>
  );
};

export default InputError;
