import React, { useState } from 'react';
import tw from 'tailwind-styled-components';

export interface InputProps {
  value?: string;
  name: string;
  placeholder?: string;
  type: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement, Element>) => void;
}

const SInput = tw.textarea`
block
w-full
px-5
py-3
text-base text-gray-600
placeholder-gray-300
transition
duration-500
shadow-md
ease-in-out
transform
border border-transparent
rounded-lg
bg-gray-50
focus:outline-none
focus:border-transparent
focus:ring-2
focus:ring-white
focus:ring-offset-2
focus:ring-offset-gray-300
apearance-none
resize-none
autoexpand`;

const Textarea = ({
  name,
  value,
  type,
  placeholder,
  onChange,
  onBlur,
}: InputProps) => {
  const [currentValue, setCurrentValue] = useState(value);
  return (
    <SInput
      name={name}
      placeholder={placeholder}
      value={currentValue}
      onChange={(e) => {
        if (onChange) onChange(e);
        setCurrentValue(e.currentTarget.value);
      }}
      onBlur={onBlur}
    />
  );
};

export default Textarea;
