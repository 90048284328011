import ProfileMenu from './ProfileMenu';

type NavigationProps = {
  navigation?: React.ReactNode;
  right?: React.ReactNode;
};

const styles = {
  wrapper: 'flex justify-between items-center w-full',
  links: 'flex flex-wrap justify-between items-center -mx-2 list-none',
};

const Navigation = ({ navigation }: NavigationProps) => {
  return (
    <nav className={styles.wrapper}>
      <div className='flex items-center'>
        <div className='flex items-center'>
          <a className='flex-shrink-0 mr-20' href='/'>
            <img className='h-8 w-auto' src='../../ioa-logo.svg' alt='Logo' />
          </a>
        </div>
        <div className={styles.links}>{navigation}</div>
      </div>
      <div className='flex items-center justify-center'>
        <ProfileMenu></ProfileMenu>
      </div>
    </nav>
  );
};

export default Navigation;
