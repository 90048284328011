import React from 'react';
import { useQuery } from '@apollo/client';
import * as queries from '../../../queries';
import { BiCategory } from 'react-icons/bi';
import { IconContext } from 'react-icons';

interface Props {}

const styles = {
  wrapper: 'bg-gray-200',
  container: 'w-9/12 m-auto py-8',
  title: 'text-center font-bold text-4xl mb-8',
  categories: 'flex justify-between font-bold',
  icon: 'h-16 w-16 p-4 bg-white rounded-full m-auto mb-4 border-2 border-gray-400',
  subtitle: 'text-center text-xl font-semibold leading-tight my-8',
  listItem: 'text-center m-auto',
};

const Categories = (props: Props) => {
  const { loading, error, data } = useQuery(queries.CATEGORIES);
  let categories;
  if (error) return <p>Error</p>;

  if (!loading) {
    categories = data.categories.map((category: any, index: any) => {
      return (
        <li key={index}>
          <IconContext.Provider value={{ className: styles.icon }}>
            <BiCategory />
          </IconContext.Provider>
          <p className={styles.listItem}>{category.name}</p>
        </li>
      );
    });
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h2 className={styles.title}>Inventory Categories</h2>
        <h3 className={styles.subtitle}>
          Here you can filter for products based on these categories:
        </h3>
        <div>
          <ul className={styles.categories}>{categories}</ul>
        </div>
      </div>
    </div>
  );
};

export default Categories;
