import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type AccordionProps = {
  children: React.ReactNode;
  title: string;
  panelId: Number;
};

export default function AccordionComponent({
  children,
  title,
  panelId,
}: AccordionProps) {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <Accordion
      expanded={expanded === 'panel' + panelId}
      onChange={handleChange('panel' + panelId)}
      sx={{
        boxShadow: 2,
        fontFamily: 'Inter',
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={'panel' + panelId + 'bh-content'}
        id={'panel' + panelId + 'bh-header'}
      >
        <Typography
          sx={{
            width: '33%',
            flexShrink: 0,
            fontSize: '1.8rem',
            fontWeight: '800',
            fontFamily: 'Inter',
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
