import * as React from "react";
import { useEffect } from "react";
import * as Routes from "../routes";
import AppBar from "@mui/material/AppBar";
import { Link, useHistory, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import DevicesIcon from "@mui/icons-material/Devices";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import OutboundIcon from "@mui/icons-material/Outbound";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import FaceIcon from "@mui/icons-material/Face";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { ListItemButton, styled } from "@mui/material";
import { useState } from "react";
import CategoryIcon from "@mui/icons-material/Category";
import ClassIcon from "@mui/icons-material/Class";
import { useUser } from "../contexts/AuthenticatedContext";

const drawerWidth = 240;

const styles = {
  flex: "w-full flex justify-between",
};

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const TopSection = styled(Box)`
  display: flex;
  height: 64px;
  align-items: center;
  margin: 0 1rem 1rem;
`;

const ResponsiveDrawer: React.FC<Props> = ({ children }, props: Props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openDevices, setOpenDevices] = React.useState(true);
  const [openUsers, setOpenUsers] = React.useState(true);
  const [openCategories, setOpenCategories] = React.useState(true);
  const [openTypes, setOpenTypes] = React.useState(true);
  const location = useLocation();
  const history = useHistory();
  const handleUserContext = useUser();

  const fetchAuthentication = async () => {
    if (handleUserContext!.state.id === undefined) {
      // Check if user is logged in, if not, fetch session state
      const request = await fetch(
        "https://belgian-waffles-server.herokuapp.com/isAuthenticated",
        {
          method: "GET",
          credentials: "include",
        }
      );

      const response = await request.json();
      if (response.statusCode === 403) {
        handleUserContext!.dispatch({
          type: "setUser",
          payload: { email: undefined, id: undefined, roleId: undefined },
        });
      } else {
        handleUserContext!.dispatch({
          type: "setUser",
          payload: {
            email: response.email,
            id: response.id,
            roleId: response.roleId,
          },
        });
      }
    }
  };

  useEffect(() => {
    fetchAuthentication();
  }, []);

  if (handleUserContext!.state.id !== undefined) {
    console.log(handleUserContext!.state);
    if (handleUserContext!.state.roleId !== 2) {
      console.log("Not admin");
      history.push("/");
    }
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleDevicesClick = () => {
    setOpenDevices((prev) => !prev);
  };

  const handleUsersClick = () => {
    setOpenUsers((prev) => !prev);
  };

  const handleCategoriesClick = () => {
    setOpenCategories((prev) => !prev);
  };

  const handleTypesClick = () => {
    setOpenTypes((prev) => !prev);
  };

  const drawer = (
    <div>
      {/* <Toolbar /> */}
      <TopSection>
        <Link to={Routes.HOME}>
          <img
            src={`${process.env.PUBLIC_URL}/ioa-logo.svg`}
            className="w-14"
            alt=""
          />
        </Link>
      </TopSection>
      <List>
        <ListItemButton key={"Devices"} onClick={handleDevicesClick}>
          <ListItemIcon>
            <DevicesIcon />
          </ListItemIcon>
          <ListItemText primary={"Devices"} />
          {openDevices ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
        <Collapse in={openDevices} timeout="auto">
          <List>
            <Link to={Routes.ADMIN_DEVICES}>
              <ListItemButton
                selected={Boolean(location.pathname === Routes.ADMIN_DEVICES)}
                sx={{ pl: 4 }}
                key={"All Devices"}
              >
                <ListItemIcon>
                  <FormatListBulletedIcon />
                </ListItemIcon>
                <ListItemText primary={"All Devices"} />
              </ListItemButton>
            </Link>

            <Link to={Routes.ADMIN_DEVICES_INSTOCK}>
              <ListItemButton
                selected={Boolean(
                  location.pathname === Routes.ADMIN_DEVICES_INSTOCK
                )}
                sx={{ pl: 4 }}
                key={"In Stock"}
              >
                <ListItemIcon>
                  <BeenhereIcon />
                </ListItemIcon>
                <ListItemText primary={"In Stock"} />
              </ListItemButton>
            </Link>

            <Link to={Routes.ADMIN_DEVICES_CHECKEDOUT}>
              <ListItemButton
                selected={Boolean(
                  location.pathname === Routes.ADMIN_DEVICES_CHECKEDOUT
                )}
                sx={{ pl: 4 }}
                key={"Checked Out"}
              >
                <ListItemIcon>
                  <OutboundIcon />
                </ListItemIcon>
                <ListItemText primary={"Checked Out"} />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
      </List>

      <Divider />

      <List>
        <ListItem button key={"All Users"} onClick={handleUsersClick}>
          <ListItemIcon>
            <PeopleAltIcon />
          </ListItemIcon>
          <ListItemText primary={"Users"} />
          {openUsers ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={openUsers} timeout="auto">
          <List>
            <Link to={Routes.ADMIN_USERS}>
              <ListItemButton
                sx={{ pl: 4 }}
                selected={Boolean(location.pathname === Routes.ADMIN_USERS)}
                key={"All Users"}
              >
                <ListItemIcon>
                  <FormatListBulletedIcon />
                </ListItemIcon>
                <ListItemText primary={"All Users"} />
              </ListItemButton>
            </Link>

            <Link to={Routes.ADMIN_USERS_SUPERADMINS}>
              <ListItemButton
                sx={{ pl: 4 }}
                selected={Boolean(
                  location.pathname === Routes.ADMIN_USERS_SUPERADMINS
                )}
                key={"Super Admins"}
              >
                <ListItemIcon>
                  <AdminPanelSettingsIcon />
                </ListItemIcon>
                <ListItemText primary={"Super Admins"} />
              </ListItemButton>
            </Link>

            <Link to={Routes.ADMIN_USERS_ADMINS}>
              <ListItemButton
                sx={{ pl: 4 }}
                selected={Boolean(
                  location.pathname === Routes.ADMIN_USERS_ADMINS
                )}
                key={"Admins"}
              >
                <ListItemIcon>
                  <VerifiedUserIcon />
                </ListItemIcon>
                <ListItemText primary={"Admins"} />
              </ListItemButton>
            </Link>

            <Link to={Routes.ADMIN_USERS_STUDENTS}>
              <ListItemButton
                sx={{ pl: 4 }}
                selected={Boolean(
                  location.pathname === Routes.ADMIN_USERS_STUDENTS
                )}
                key={"Students"}
              >
                <ListItemIcon>
                  <MenuBookIcon />
                </ListItemIcon>
                <ListItemText primary={"Students"} />
              </ListItemButton>
            </Link>

            <Link to={Routes.ADMIN_USERS_LECTURERS}>
              <ListItemButton
                sx={{ pl: 4 }}
                selected={Boolean(
                  location.pathname === Routes.ADMIN_USERS_LECTURERS
                )}
                key={"Lecturers"}
              >
                <ListItemIcon>
                  <FaceIcon />
                </ListItemIcon>
                <ListItemText primary={"Lecturers"} />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
      </List>

      <Divider />

      <List>
        <ListItem button key={"Categories"} onClick={handleCategoriesClick}>
          <ListItemIcon>
            <CategoryIcon />
          </ListItemIcon>
          <ListItemText primary={"Categories"} />
          {openCategories ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={openCategories} timeout="auto">
          <List>
            <Link to={Routes.ADMIN_CATEGORIES}>
              <ListItemButton
                sx={{ pl: 4 }}
                selected={Boolean(
                  location.pathname === Routes.ADMIN_CATEGORIES
                )}
                key={"All Categories"}
              >
                <ListItemIcon>
                  <FormatListBulletedIcon />
                </ListItemIcon>
                <ListItemText primary={"All Categories"} />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
      </List>

      <Divider />

      <List>
        <ListItem button key={"Types"} onClick={handleTypesClick}>
          <ListItemIcon>
            <ClassIcon />
          </ListItemIcon>
          <ListItemText primary={"Types"} />
          {openTypes ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={openTypes} timeout="auto">
          <List>
            <Link to={Routes.ADMIN_TYPES}>
              <ListItemButton
                sx={{ pl: 4 }}
                selected={Boolean(location.pathname === Routes.ADMIN_TYPES)}
                key={"All Types"}
              >
                <ListItemIcon>
                  <FormatListBulletedIcon />
                </ListItemIcon>
                <ListItemText primary={"All Types"} />
              </ListItemButton>
            </Link>
          </List>
        </Collapse>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  let localTheme: any = "dark";
  const [darkMode, setDarkmode] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("theme") === "dark") {
      setDarkmode(true);
    }
  }, []);

  if (localStorage.getItem("theme")) {
    localTheme = localStorage.getItem("theme");
  } else {
    localStorage.setItem("theme", "dark");
  }

  const toggleTheme = () => {
    if (localStorage) {
      const theme = localStorage.getItem("theme");
      if (theme === "dark") {
        localStorage.setItem("theme", "light");
        setDarkmode(false);
      } else {
        localStorage.setItem("theme", "dark");
        setDarkmode(true);
      }
    }
  };

  const theme = createTheme({
    palette: {
      mode: localTheme,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar>
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <div className={styles.flex}>
              <Typography variant="h6" noWrap component="div">
                Inventory of Artevelde
              </Typography>
              <Switch checked={darkMode} onChange={() => toggleTheme()} />
            </div>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Toolbar />
          <main>{children}</main>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ResponsiveDrawer;
