import Container from "./Container";

type HeaderProps = {
  children: React.ReactNode;
};

const styles = {
  base: "bg-white w-full flex h-20 border-b border-gray-100 items-center",
};

const Header = ({ children }: HeaderProps) => {
  return (
    <header className={styles.base}>
      <Container $fullWidth>{children}</Container>
    </header>
  );
};

export default Header;
