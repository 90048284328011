import tw from "tailwind-styled-components";
import { FaFacebookF } from "react-icons/fa";
import {
  AiOutlineMail,
  AiOutlineTwitter,
  AiOutlineInstagram,
} from "react-icons/ai";

const SIconList = tw.ul`
  w-4/5
  mx-auto
  md:mx-2
  justify-between
  md:w-auto
  md:m-0
  flex
  h-12
`;

const SIcon = tw.li`
bg-white
items-center
m-auto
rounded-full
w-12
md:ml-2
h-12
flex
justify-center
border-2
border-gray-200
text-2xl
hover:bg-blue-300
hover:text-white
cursor-pointer
`;

const SCopyright = tw.p`
pt-12
font-bold
text-center  
md:hidden
`;

interface Props {}

const FooterIcons = (props: Props) => {
  return (
    <>
      <SIconList>
        <a href="https://www.facebook.com/arteveldehogeschool">
          <SIcon>
            <FaFacebookF />
          </SIcon>
        </a>
        <a href="mailto:info@arteveldehs.be">
          <SIcon>
            <AiOutlineMail />
          </SIcon>
        </a>
        <a href="https://twitter.com/ArteveldehsGent?s=20">
          <SIcon>
            <AiOutlineTwitter />
          </SIcon>
        </a>
        <a href="https://www.instagram.com/arteveldehogeschool/">
          <SIcon>
            <AiOutlineInstagram />
          </SIcon>
        </a>
      </SIconList>
      <SCopyright>
        Copyright © 2021 Inventory of Artevelde. All rights reserved.
      </SCopyright>
    </>
  );
};

export default FooterIcons;
