const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'firstname',
    headerName: 'First Name',
    width: 180,
  },
  {
    field: 'lastname',
    headerName: 'Last Name',
    width: 180,
  },
  {
    field: 'email',
    headerName: 'Email Address',
    width: 180,
  },
  {
    field: 'role',
    headerName: 'Role',
    width: 180,
  },
];

export default columns;
