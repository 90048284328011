import Header from '../components/layout/Header';
import Navigation from '../components/layout/Navigation';
import NavigationLinks from '../components/layout/NavigationLinks';
import Footer from '../components/layout/Footer/Footer';
import Hero from '../components/layout/Hero';
import React, { useEffect } from 'react';
import { useUser } from '../contexts/AuthenticatedContext';

interface Props {
  children: React.ReactNode;
  title?: string;
  subtitle?: string;
  hasHero: boolean;
  backgroundImage?: string;
}

const Base: React.FC<Props> = ({
  children,
  title,
  hasHero,
  subtitle,
  backgroundImage,
}) => {
  const handleUserContext = useUser();

  const fetchAuthentication = async () => {
    if (handleUserContext!.state.id === undefined) {
      // Check if user is logged in, if not, fetch session state
      const request = await fetch(
        'https://belgian-waffles-server.herokuapp.com/isAuthenticated',
        {
          method: 'GET',
          credentials: 'include',
        }
      );
      const response = await request.json();
      if (response.statusCode === 403) {
        handleUserContext!.dispatch({
          type: 'setUser',
          payload: { email: undefined, id: undefined, roleId: undefined },
        });
      } else {
        handleUserContext!.dispatch({
          type: 'setUser',
          payload: {
            email: response.email,
            id: response.id,
            roleId: response.roleId,
          },
        });
      }
    }
  };

  useEffect(() => {
    fetchAuthentication();
  }, []);

  return (
    <div className='bg-gray-50'>
      <Header>
        <Navigation
          navigation={<NavigationLinks />}
          right='Plain string for now'
        />
      </Header>
      {hasHero && (
        <Hero
          $title={title}
          $subtitle={subtitle}
          backgroundImage={backgroundImage}
        />
      )}
      <main>{children}</main>
      <Footer />
    </div>
  );
};

export default Base;
