import tw from 'tailwind-styled-components';

const SFooterTitle = tw.h2`
  text-3xl
  font-bold
  mb-4
`;

const SFooterInfo = tw.div`
pb-8
md:pb-0
leading-8
text-lg
font-bold
`;

const SCopyright = tw.p`
  hidden
  md:block
  pt-4
`;

interface Props {}

const FooterInfo = (props: Props) => {
  return (
    <SFooterInfo>
      <SFooterTitle>Inventory of Artevelde</SFooterTitle>
      <SFooterInfo>
        <p>Industrieweg 323</p>
        <p>9030 Mariakerke</p>
        <p>09 234 86 00</p>
        <SCopyright>
          Copyright © 2021 Inventory of Artevelde. All rights reserved.
        </SCopyright>
      </SFooterInfo>
    </SFooterInfo>
  );
};

export default FooterInfo;
