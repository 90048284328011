import React, { useState } from "react";
import {
  DataGrid,
  GridColumns,
  GridRowsProp,
  GridSortModel,
} from "@mui/x-data-grid";

interface DatagridProps {
  columns: GridColumns;
  rows: GridRowsProp;
}

const styles = {
  grid: "mt-8",
};

const Datagrid = ({ columns, rows }: DatagridProps) => {
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "id", sort: "asc" },
  ]);

  return (
    <div className={styles.grid}>
      <DataGrid
        rows={rows}
        columns={columns}
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        density={"comfortable"}
        checkboxSelection
        autoHeight
      />
    </div>
  );
};

export default Datagrid;
