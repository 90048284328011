type ButtonProps = {
  buttonType: 'primary' | 'secondary' | 'tertiary';
  $fullWidth?: boolean;
  children?: React.ReactNode;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const styles = {
  base: `text-white font-bold py-2 px-4 rounded-sm transition-all duration-300 transform ease-in-out focus:outline-none`,
  primary: `bg-indigo-800 hover:bg-indigo-500`,
  secondary: `bg-blue-800 hover:bg-blue-500`,
  tertiary: `bg-purple-800 hover:bg-purple-500`,
};

const Button = ({ $fullWidth, buttonType, children, onClick }: ButtonProps) => {
  return (
    <button
      className={`${styles.base} ${styles[buttonType]} ${
        $fullWidth ? 'w-full' : ''
      }`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default Button;
