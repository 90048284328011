import { useMutation, useQuery } from "@apollo/client";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import { Formik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import AdminLayout from "../../../layouts/AdminLayout";
import * as queries from "../../../queries";

interface Props {}

type DetailParams = {
  id?: string | undefined;
};

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  description: yup.string().required("Description is required"),
});

const AddCategory = (props: Props) => {
  const { id } = useParams<DetailParams>();
  const history = useHistory();

  const { data: getCatData } = useQuery(queries.GET_CATEGORY_BY_ID, {
    variables: { id: Number(id) },
  });

  const [addCategory] = useMutation(queries.UPDATE_CATEGORY);

  return (
    <AdminLayout>
      {getCatData && (
        <Container maxWidth="xl">
          <Typography variant="h3" my={"2rem"}>
            Update Category
          </Typography>
          <Box
            sx={{
              border: "1px solid #bdbdbd",
              padding: "2rem",
              borderRadius: "10px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
            }}
          >
            <div>
              <Formik
                initialValues={{
                  name: getCatData.getCategory.name,
                  description: getCatData.getCategory.description,
                }}
                onSubmit={async (data, { setSubmitting }) => {
                  setSubmitting(true);

                  addCategory({
                    variables: {
                      id: Number(id),
                      name: data.name,
                      description: data.description,
                      icon_url: "test",
                    },
                    refetchQueries: [
                      {
                        query: queries.CATEGORIES,
                      },
                    ],
                  });

                  history.push("/admin/categories");

                  // handleClose();

                  setSubmitting(false);
                }}
                validationSchema={validationSchema}
              >
                {({
                  values,
                  isSubmitting,
                  handleBlur,
                  handleSubmit,
                  handleChange,
                  touched,
                  errors,
                }) => (
                  <form className="grid gap-y-4" onSubmit={handleSubmit}>
                    <TextField
                      fullWidth
                      id="name"
                      name="name"
                      label="Name"
                      value={values.name || ""}
                      onChange={handleChange}
                      error={Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                    />
                    <TextField
                      multiline={true}
                      rows={3}
                      fullWidth
                      id="description"
                      name="description"
                      label="description"
                      value={values.description}
                      onChange={handleChange}
                      error={Boolean(errors.description)}
                      helperText={touched.description && errors.description}
                    />
                    <DialogActions>
                      <Button
                        type="submit"
                        variant="contained"
                        disabled={isSubmitting}
                      >
                        Add Device
                      </Button>
                    </DialogActions>
                  </form>
                )}
              </Formik>
            </div>
          </Box>
        </Container>
      )}
    </AdminLayout>
  );
};

export default AddCategory;
