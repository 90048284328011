import { useState } from 'react';
import SearchField from '../../../components/layout/SearchField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useMutation, useQuery } from '@apollo/client';
import * as queries from '../../../queries';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Snackbar,
  Button,
  Select,
  TextField,
} from '@mui/material';

interface Props {}

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required'),
  category: yup.string().required('Category is required'),
  type: yup.string().required('Type is required'),
  user: yup.string().required('User is required'),
  specs: yup.string().required('Specifications are required'),
});

const styles = {
  flex: 'flex items-center justify-between',
};

const AddDevice = (props: Props) => {
  const [open, setOpen] = useState(false);

  const [addDevice, { loading, error }] = useMutation(queries.ADD_DEVICE);

  const { data: usersData } = useQuery(queries.USERS);

  const { data: categoriesData } = useQuery(queries.CATEGORIES);

  const { data: typesData } = useQuery(queries.TYPES);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={styles.flex}>
      <SearchField />
      <Button
        variant='contained'
        children='Add Item'
        onClick={handleClickOpen}
      />
      {error && (
        <Snackbar
          open={!!error}
          message={error.message}
          onClose={() => setOpen(false)}
        />
      )}      
      <Dialog fullWidth open={open} onClose={handleClose} keepMounted>
        <DialogTitle>Add Device</DialogTitle>
        <DialogContent>
          <DialogContentText mb={'1rem'}>
            To add a new device, please fill out the form below.
          </DialogContentText>
          <div>
            <Formik
              initialValues={{
                name: '',
                description: '',
                category: '',
                user: '',
                type: '',
                status: true,
                specs: '',
              }}
              onSubmit={async (data, { setSubmitting }) => {
                setSubmitting(true);

                addDevice({
                  variables: {
                    name: data.name,
                    typeId: parseInt(data.type),
                    is_available: data.status,
                    description: data.description,
                    userId: 1,
                    categoryId: parseInt(data.category),
                    qr_code: 'this is a qr code',
                    specifications: [data.specs],
                  },
                  refetchQueries: [
                    {
                      query: queries.DEVICES,
                    },
                  ],
                });

                handleClose();

                setSubmitting(false);
              }}
              validationSchema={validationSchema}
            >
              {({
                values,
                isSubmitting,
                handleBlur,
                handleSubmit,
                handleChange,
                touched,
                errors,
              }) => (
                <form className='grid gap-y-4' onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    id='name'
                    name='name'
                    label='Name'
                    value={values.name}
                    onChange={handleChange}
                    error={Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                  <FormControl>
                    <InputLabel id='user'>User</InputLabel>
                    <Select
                      labelId='user'
                      id='user'
                      name='user'
                      error={Boolean(errors.user)}
                      label='User'
                      onChange={handleChange}
                    >
                      {usersData &&
                        usersData.users
                          .slice(1)
                          .map(
                            (user: {
                              id: number;
                              firstname: string;
                              lastname: string;
                            }) => (
                              <MenuItem key={user.id} value={user.id}>
                                {`${user.firstname} ${user.lastname}`}
                              </MenuItem>
                            )
                          )}
                    </Select>
                    <FormHelperText sx={{ color: '#f44336' }}>
                      {touched.user && errors.user}
                    </FormHelperText>
                  </FormControl>
                  <FormControl>
                    <InputLabel id='category'>Category</InputLabel>
                    <Select
                      labelId='category'
                      id='category'
                      error={Boolean(errors.category)}
                      name='category'
                      label='Category'
                      onChange={handleChange}
                    >
                      {categoriesData &&
                        categoriesData.categories.map(
                          (category: { name: string; id: number }) => (
                            <MenuItem key={category.id} value={category.id}>
                              {`${category.name}`}
                            </MenuItem>
                          )
                        )}
                    </Select>
                    <FormHelperText sx={{ color: '#f44336' }}>
                      {touched.category && errors.category}
                    </FormHelperText>
                  </FormControl>
                  <FormControl>
                    <InputLabel id='type'>Type</InputLabel>
                    <Select
                      labelId='type'
                      id='type'
                      name='type'
                      label='Type'
                      error={Boolean(errors.type)}
                      onChange={handleChange}
                    >
                      {typesData &&
                        typesData.types
                          .slice(1)
                          .map((type: { name: string; id: number }) => (
                            <MenuItem key={type.id} value={type.id}>
                              {`${type.name}`}
                            </MenuItem>
                          ))}
                    </Select>
                    <FormHelperText sx={{ color: '#f44336' }}>
                      {touched.type && errors.type}
                    </FormHelperText>
                  </FormControl>
                  <TextField
                    fullWidth
                    id='specs'
                    name='specs'
                    label='specs'
                    value={values.specs}
                    onChange={handleChange}
                    error={Boolean(errors.specs)}
                    helperText={
                      errors.specs ||
                      "Enter specifications seperated by comma, for example: 'specification1, specification2, ...'"
                    }
                  />
                  <TextField
                    multiline={true}
                    rows={3}
                    fullWidth
                    id='description'
                    name='description'
                    label='description'
                    value={values.description}
                    onChange={handleChange}
                    error={Boolean(errors.description)}
                    helperText={touched.description && errors.description}
                  />
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      name='status'
                      label='Is in stock'
                    />
                  </FormGroup>
                  <DialogActions>
                    <Button
                      type='submit'
                      variant='contained'
                      fullWidth
                      disabled={isSubmitting}
                    >
                      Add Device
                    </Button>
                  </DialogActions>
                </form>
              )}
            </Formik>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddDevice;
