import { Link } from 'react-router-dom';

import * as Routes from '../../routes';

const styles = {
  link: 'text-gray-400 hover:text-indigo-500 transition-all duration-200 cursor-pointer mr-12 text-base',
  wrapper: 'flex flex-wrap',
};

const NavigationLinks = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.link}>
        <Link to={Routes.HOME}>Home</Link>
      </div>
      <div className={styles.link}>
        <Link to={Routes.FAQ}>FAQ</Link>
      </div>
      <div className={styles.link}>
        <Link to={Routes.CONTACT}>Contact</Link>
      </div>
      <div className={styles.link}>
        <Link to={Routes.DEVICES}>Devices</Link>
      </div>
    </div>
  );
};

export default NavigationLinks;
