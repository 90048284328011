import React, { useState } from "react";
import tw from "tailwind-styled-components";

export interface InputProps {
  value?: string;
  name: string;
  placeholder?: string;
  type: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
}

const SInput = tw.input`
block
w-full
px-5
py-3
text-base text-gray-600
placeholder-gray-300
transition
duration-500
ease-in-out
shadow-md
transform
border border-transparent
rounded-lg
bg-gray-50
focus:outline-none
focus:border-transparent
focus:ring-2
focus:ring-white
focus:ring-offset-2
focus:ring-offset-gray-300`;
const Input = ({
  name,
  value,
  type,
  placeholder,
  onChange,
  onBlur,
}: InputProps) => {
  const [currentValue, setCurrentValue] = useState(value);
  return (
    <SInput
      type={type}
      name={name}
      placeholder={placeholder}
      value={currentValue}
      onChange={(e) => {
        if (onChange) onChange(e);
        setCurrentValue(e.currentTarget.value);
      }}
      onBlur={onBlur}
    />
  );
};

export default Input;
