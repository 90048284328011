import { useParams } from "react-router-dom";
import BaseLayout from "../layouts/Base";
import Container from "../components/layout/Container";
import Detail from "../components/layout/Detail";
import { useQuery } from "@apollo/client";
import * as queries from "../queries";

interface Props {}

type DetailParams = {
  id?: string | undefined;
  slug?: string | undefined;
};

const DetailPage = (props: Props) => {
  const { id } = useParams<DetailParams>();

  const { loading, error, data } = useQuery(queries.GET_DEVICE, {
    variables: {
      id: Number(id),
    },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :</p>;

  return (
    <BaseLayout hasHero={false}>
      <Container>
        <div className="my-10">
          <Detail
            image={`${process.env.PUBLIC_URL}/images/device/${data.getDevice.picture_url}`}
            title={data.getDevice.name}
            specs={data.getDevice.specifications}
            id={data.getDevice.id}
            description={data.getDevice.description}
            category={data.getDevice.category.name}
            type={data.getDevice.type.name}
            is_available={data.getDevice.is_available}
          ></Detail>
        </div>
      </Container>
    </BaseLayout>
  );
};

export default DetailPage;
