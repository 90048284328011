export const HOME = '/';

export const DEVICES = '/devices';
export const DEVICE_DETAIL = '/devices/:id/:slug';

export const AUTH_SIGN_IN = '/auth/login';
export const AUTH_SIGN_OUT = '/auth/signout';
export const AUTH_REGISTER = '/auth/register';

export const FAQ = '/faq';
export const CART = '/cart';
export const PROFILE = '/profile';
export const CONTACT = '/contact';

export const ADMIN = '/admin';
export const ADMIN_USERS = '/admin/users';
export const ADMIN_USERS_ADMINS = '/admin/admins';
export const ADMIN_USERS_SUPERADMINS = '/admin/superadmins';
export const ADMIN_USERS_STUDENTS = '/admin/students';
export const ADMIN_USERS_LECTURERS = '/admin/lecturers';
export const ADMIN_DEVICES = '/admin/devices';

export const ADMIN_DEVICES_EDIT = '/admin/devices/edit/:id';
export const ADMIN_DEVICES_DELETE = '/admin/devices/delete/:id';

export const ADMIN_TYPES_EDIT = '/admin/types/edit/:id';
export const ADMIN_TYPES_DELETE = '/admin/types/delete/:id';

export const ADMIN_CATEGORIES_EDIT = '/admin/categories/edit/:id';
export const ADMIN_CATEGORIES_DELETE = '/admin/categories/delete/:id';

export const ADMIN_DEVICES_INSTOCK = '/admin/instock';
export const ADMIN_DEVICES_CHECKEDOUT = '/admin/checkedout';
export const ADMIN_CATEGORIES = '/admin/categories';
export const ADMIN_TYPES = '/admin/types';
