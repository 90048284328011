import React from 'react';
import { Map, Marker } from 'pigeon-maps';

interface Props {}

const MapBox = (props: Props) => {
  return (
    <Map
      defaultCenter={[51.078400653048824, 3.712337631950886]}
      height={610}
      defaultZoom={12}
    >
      <Marker
        anchor={[51.087278977328374, 3.6687478365210535]}
        payload={1}
        width={50}
      />
    </Map>
  );
};

export default MapBox;
