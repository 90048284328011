import React from 'react';
import { useQuery } from '@apollo/client';
import * as queries from '../../../queries';
import Grid from '../Grid';
import Card from '../Card/Card';

interface Props {}

const styles = {
  title: 'text-center font-bold text-4xl my-8',
  subtitle: 'text-center text-xl font-semibold leading-tight my-8',
  wrapper: 'mb-12',
};

const Interests = (props: Props) => {
  const { loading, error, data } = useQuery(queries.DEVICES);
  let devices;
  if (error) return <p>Error :</p>;

  if (!loading) {
    devices = data.devices.slice(0, 3).map((device: any) => {
      return (
        <Card
          link={`/devices/${device.id}/${device.name
            .toLowerCase()
            .split(' ')
            .join('_')}`}
          key={device.id}
          title={device.name}
          image={device.picture_url}
          category={device.category.name}
          type={device.type.name}
          specifications={device.specifications}
        />
      );
    });
  }

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.title}>These items might interest you</h2>
      <Grid>{devices}</Grid>
    </div>
  );
};

export default Interests;
