import Container from "../../components/layout/Container";
import BaseLayout from "../../layouts/Base";
import Register from "../../components/layout/Auth/Register";

interface Props {}

const RegisterPage = (props: Props) => {
  return (
    <BaseLayout hasHero={false}>
      <Container>
        <div className="my-20">
          <h1 className="text-4xl font-bold mb-8 text-center">Register</h1>
          <Register />
        </div>
      </Container>
    </BaseLayout>
  );
};

export default RegisterPage;
