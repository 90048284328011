import React, { useState } from 'react';
import tw from 'tailwind-styled-components';

export interface InputProps {
  value?: string;
  name: string;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const SInput = tw.select`
block
w-full
px-5
py-3
text-base text-gray-600
placeholder-gray-300
transition
duration-500
ease-in-out
transform
border border-transparent
rounded-lg
bg-gray-50
focus:outline-none
focus:border-transparent
focus:ring-2
focus:ring-white
focus:ring-offset-2
focus:ring-offset-gray-300
apearance-none
autoexpand
`;

const SOption = tw.option`
  text-gray-800
`;

const Select = ({ name, value, placeholder, onChange }: InputProps) => {
  const [currentValue, setCurrentValue] = useState(value);

  return (
    <SInput
      value={currentValue}
      name={name}
      placeholder={placeholder}
      onChange={(e) => {
        if (onChange) onChange(e);
        setCurrentValue(e.currentTarget.value);
      }}
    >
      <SOption value={1} key={1}>
        Student
      </SOption>
      <SOption value={2} key={2}>
        Lecturer
      </SOption>
    </SInput>
  );
};

export default Select;
