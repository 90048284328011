import BaseLayout from '../layouts/Base';
import Accordion from '../components/Accordion';
import { Typography } from '@mui/material';
import Container from '../components/layout/Container';
import { Box } from '@mui/system';

interface Props {}

const FAQ = (props: Props) => {
  return (
    <BaseLayout
      hasHero={true}
      title='FAQ'
      subtitle='Frequently Asked Questions'
      backgroundImage='https://i.pinimg.com/originals/b8/4f/41/b84f4195a6d33c93d01111287c0c7b7e.jpg'
    >
      {/* <Hero $title="FAQ" $subtitle="Frequently Asked Questions" /> */}
      <Container>
        {/* <Button onClick={fetchProtected} buttonType="primary">
          Fetch
        </Button> */}
        <div className='my-8'>
          <h1 className='text-4xl my-8 font-bold'>
            {' '}
            Frequently Asked Questions
          </h1>
          <Accordion title='General Inquiries' panelId={1}>
            <Typography>
              <Box
                display='block'
                component='span'
                fontWeight='500'
                fontSize='1.5rem'
                my={2}
              >
                What is the IOT’s response to COVID-19?
              </Box>
              To keep you and our employees safe, we are limiting the available
              timeslots per house to pick up reserved devices. In addition,
              areas with large amounts of traffic in our library where users
              come to pick up are cleaned hourly. Devices are isolated for 24
              hours upon return to our library and are thoroughly disinfected
              twice prior to it’s next pickup. If you have been exposed to
              COVID-19 or show any symptoms related, please cancel your
              reservation with us by phone or submit a problem form on our
              website.
              <Box
                display='block'
                component='span'
                fontWeight='500'
                fontSize='1.5rem'
                my={2}
              >
                Who can borrow a device?
              </Box>
              Artevelde students and staff are allowed to borrow devices through
              the IOT. This includes: Artevelde lecturers, professors, teaching
              assistants, and both full-time and part-time students. Students
              wishing to borrow a device must be in good financial standing and
              not owe Artevelde any outstanding fines over €25.
              <Box
                display='block'
                component='span'
                fontWeight='500'
                fontSize='1.5rem'
                my={2}
              >
                Who is responsible for theft or damages?
              </Box>
              The individual who reserved and picked up the device on campus is
              responsible for late and damaged devices. By picking up a reserved
              product, you are accepting our terms of use and associated
              supplementary fees. These terms of use have been put in place to
              prevent theft and enforce the timeline you have chosen for your
              rental device. Fees will be charged to you through an invoice sent
              by email to your Artevelde email used to access the IOT and
              reserved your device from.
            </Typography>
          </Accordion>
          <Accordion title='Users' panelId={2}>
            <Typography>
              <Box
                display='block'
                component='span'
                fontWeight='500'
                fontSize='1.5rem'
                my={2}
              >
                When, where and for how long can I borrow a device?
              </Box>
              You decide how long you need your device for. Devices are
              unavailable for use for over 2 weeks (14 days) at a time. When you
              come in to return your device, you will be given the option to
              re-reserve the same product and begin a new timeframe to return it
              depending on device availability at that time.
              <Box
                display='block'
                component='span'
                fontWeight='500'
                fontSize='1.5rem'
                my={2}
              >
                What do I get when I borrow a device?
              </Box>
              All available devices on the IOT come with (1) the device you have
              reserved that has been thoroughly cleaned prior to your pickup
              timeframe and (2) a charger to charge the device from an outlet.
              <Box
                display='block'
                component='span'
                fontWeight='500'
                fontSize='1.5rem'
                my={2}
              >
                How do I save my personal files on a borrowed device?
              </Box>
              It is required to save your personal files to a USB. Do not save
              files directly to the computer or your files may be accessed by
              future device users. Ensure all personal files have been removed
              from the device’s hard drive prior to return.
              <Box
                display='block'
                component='span'
                fontWeight='500'
                fontSize='1.5rem'
                my={2}
              >
                What if I need another question answered?
              </Box>
              If you have another inquiry or issue you want to bring to our
              attention, submit a problem form. Read this topic for more
              information on how to do this.
            </Typography>
          </Accordion>
          <Accordion title='Administrators' panelId={3}>
            <Typography>
              <Box
                display='block'
                component='span'
                fontWeight='500'
                fontSize='1.5rem'
                my={2}
              >
                What if I need help?
              </Box>
              Reach out to other library employees who use the IOT. If they are
              unable to help, reach out to your library superadmin for further
              issues.
              <Box
                display='block'
                component='span'
                fontWeight='500'
                fontSize='1.5rem'
                my={2}
              >
                My dashboard is stuck on a previous date or time. What do I do?
              </Box>
              Restart the device you are using and try again. If the problem
              persists, ensure the IOT is up to date. If it is, reach out to
              your superadmin (library manager) and bring the issue to their
              attention.
              <Box
                display='block'
                component='span'
                fontWeight='500'
                fontSize='1.5rem'
                my={2}
              >
                Why is my administrative login showing me an error? It worked
                yesterday.
              </Box>
              You may need to update the IOT. This is common when the IOT has
              been updated. Devices that use the IOT are set up to update
              automatically. Due to connectivity issues, the update may need to
              be done manually. Bring this to the attention of your library
              manager to get it resolved.
              <Box
                display='block'
                component='span'
                fontWeight='500'
                fontSize='1.5rem'
                my={2}
              >
                A device has not been returned. What do I do?
              </Box>
              Create a new entry on the library’s late devices spreadsheet. Your
              library manager will have access to a master copy. Inform your
              library manager via email to get the device added to this
              spreadsheet.
              <Box
                display='block'
                component='span'
                fontWeight='500'
                fontSize='1.5rem'
                my={2}
              >
                A device is now 7 days late and is assumed stolen. What do I do?
              </Box>
              Access the user profile of the individual who reserved and picked
              up the product for use to view their phone number. Use the library
              phone to give them a call. Leave them a message if they do not
              pick up. Send an email to your library manager via email to inform
              them the device has not been returned and with the insight you got
              from calling them or if the device is now considered stolen.
            </Typography>
          </Accordion>
        </div>
      </Container>
    </BaseLayout>
  );
};

export default FAQ;
