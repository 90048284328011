import { Helmet } from 'react-helmet';
import tw from 'tailwind-styled-components';
import Button from './Button';
import Interests from '../layout/Profile/Interests';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from '@mui/material';

interface DetailProps {
  image?: string;
  title: string;
  specs: string[];
  description?: string;
  id: string;
  category: string;
  type: string;
  is_available: boolean;
}

const SDetail = tw.div`
  flex flex-wrap w-full
`;

const SDetailImage = tw.img`
  md:w-1/2
`;

const SDetailText = tw.div`
  w-1/2
`;

const STitle = tw.h2`
  text-4xl
  font-bold
  mb-3
`;

const SSpecs = tw.h3`
  font-bold
  text-2xl
  mb-2
`;

const SSpec = tw.li`
  font-bold
  leading-7
`;

const SCategory = tw.h4`
font-bold
text-2xl
mb-4
`;

const SDescription = tw.p`
  mb-4
`;

const SInStock = tw.h4`
  font-bold
  text-green-500
  text-xl
`;

const SNotAvailable = tw.h4`
  font-bold
  text-red-500
  text-xl
`;

const Detail = ({
  image,
  title,
  specs,
  description,
  id,
  category,
  type,
  is_available,
}: DetailProps) => {
  let available;
  if (is_available === true) {
    available = 'In Stock';
  } else {
    available = 'Not Available';
  }

  // Ugly fix to transform comma separated specs into an array
  let specifications = specs[0].split(",");

  return (
    <SDetail>
      <Helmet>
        <title>Device - {title}</title>
        <meta name='description' content={`Detail page for ${title} device`} />
      </Helmet>
      <SDetailImage src={image} alt={title} />
      <SDetailText>
        {is_available ? (
          <SInStock>{available}</SInStock>
        ) : (
          <SNotAvailable>{available}</SNotAvailable>
        )}
        <div className='flex w-full items-center justify-between'>
          <STitle>{title}</STitle>
          <Tooltip title='All devices are thoroughly cleaned prior to becoming available in response to COVID-19. Add to cart to begin date and time selection from your cart.'>
            <InfoIcon
              sx={{
                ':hover': {
                  color: 'primary.main',
                },
              }}
              fontSize='large'
            />
          </Tooltip>
        </div>
        <SDescription>{description}</SDescription>
        <SCategory>Category: {category}</SCategory>

        <div className='mb-4'>
          <SSpecs>Specifications</SSpecs>
          <ul className="list-none">
            {specifications.map((item, index) => {
              return <SSpec key={index}>{item}</SSpec>;
            })}
          </ul>
        </div>
        <Button buttonType='primary' children='Add to cart' />
      </SDetailText>
      <Interests />
    </SDetail>
  );
};

export default Detail;
