import React from 'react';
import Container from '../Container';
import Button from '../Button';
import MapBox from '../Contact/MapBox';
import ContactForm from './ContactForm';
import { GrLocation } from 'react-icons/gr';
import { BsTelephoneOutbound } from 'react-icons/bs';
import { AiOutlineMail } from 'react-icons/ai';

interface Props {}

const styles = {
  title: 'text-3xl font-bold md:text-5xl mb-8',
  flexContainer: 'flex flex-wrap',
  box: 'w-full md:w-1/2 p-8',
  list: 'm-auto',
  listItem: 'text-xl flex items-center md:text-2xl mb-4 font-bold',
  icon: 'h-auto mr-4 block m-0',
};

const ContactInfo = (props: Props) => {
  const todoClick = () => {};

  return (
    <>
      <Container>
        <div className={styles.flexContainer}>
          <div className={styles.box}>
            <h2 className={styles.title}>Problems?</h2>
            <ul className={styles.list}>
              <li className={styles.listItem}>
                <div className={styles.icon}>
                  <GrLocation />
                </div>
                <span>Artevelde Campus Mariakerke</span>
              </li>
              <li className={styles.listItem}>
                <div className={styles.icon}>
                  <BsTelephoneOutbound />
                </div>
                <span>09 234 86 00</span>
              </li>
              <li className={styles.listItem}>
                <div className={styles.icon}>
                  <AiOutlineMail />
                </div>
                <span>info@arteveldehs.be</span>
              </li>
            </ul>
            <ContactForm />
            <Button
              children='Send'
              buttonType='primary'
              $fullWidth
              onClick={todoClick}
            />
          </div>
          <div className={styles.box}>
            <MapBox />
          </div>
        </div>
      </Container>
    </>
  );
};

export default ContactInfo;
