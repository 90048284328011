import React from 'react';
import { Formik, Field } from 'formik';
import InputError from '../FormComponents/InputError';
import TextareaError from '../FormComponents/TextareaError';
import * as yup from 'yup';

interface Props {}

const styles = {
  form: 'mb-4 grid gap-y-4',
};

const validationSchema = yup.object({
  firstname: yup.string().required('First name is required'),
  lastname: yup.string().required('Last name is required'),
  email: yup
    .string()
    .email('Invalid email address')
    .required('Email is required'),
  message: yup.string().required('Message is required'),
});

const ContactForm = (props: Props) => {
  return (
    <div className='bg-gray-100 p-2 rounded-lg'>
      <Formik
        initialValues={{
          firstname: '',
          lastname: '',
          email: '',
          message: '',
        }}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);

          // TODO api stuff

          setSubmitting(false);
        }}
        validationSchema={validationSchema}
      >
        {({ values, isSubmitting, handleSubmit, handleChange }) => (
          <form className={styles.form} onSubmit={handleSubmit}>
            <Field
              type='firstname'
              as={InputError}
              name='firstname'
              placeholder='First Name'
              onChange={handleChange}
            />
            <Field
              type='lastname'
              as={InputError}
              name='lastname'
              placeholder='Last Name'
              onChange={handleChange}
            />
            <Field
              type='email'
              as={InputError}
              name='email'
              placeholder='Email Address'
              onChange={handleChange}
            />
            <Field
              type='textarea'
              as={TextareaError}
              name='message'
              placeholder='Type your message...'
              onChange={handleChange}
            />
          </form>
        )}
      </Formik>
    </div>
  );
};

export default ContactForm;
