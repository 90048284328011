import {
  FAQ,
  Home,
  Cart,
  Types,
  Login,
  Contact,
  Devices,
  Profile,
  Students,
  Lecturers,
  UpdateType,
  AdminUsers,
  DeleteType,
  Categories,
  Superadmins,
  DeleteDevice,
  AdminDevices,
  UpdateDevice,
  Administrators,
  UpdateCategory,
  DeleteCategory,
  DevicesInStock,
  DevicesCheckedOut,
  Register,
} from './pages';
import { Helmet } from 'react-helmet';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  StaticRouter,
  HashRouter,
} from 'react-router-dom';
import Detail from './pages/Detail';
import { UserProvider } from './contexts/AuthenticatedContext';

function App() {
  return (
    <UserProvider>
      <div className='App'>
        <HashRouter>
          <Helmet>
            <title>Inventory of Artevelde</title>
            <meta
              name='description'
              content="Find all Artevelde University's internet of things devices"
            />
            <meta
              name='keywords'
              content='Artevelde University, Arteveldehogeschool, Internet of Things, IoT'
            />
          </Helmet>
          <Switch>
            <Route exact path={'/'} component={Home} />
            <Route exact path={'/FAQ'} component={FAQ} />
            <Route exact path={'/cart'} component={Cart} />
            <Route exact path={'/types'} component={Types} />
            <Route exact path={'/contact'} component={Contact} />
            <Route exact path={'/devices'} component={Devices} />
            <Route exact path={'/devices/:id/:slug'} component={Detail} />
            <Route exact path={'/profile'} component={Profile} />
            <Route exact path={'/admin'}>
              <Redirect strict from={'/admin'} to={'/admin/devices'} />
            </Route>
            <Route exact path={'/admin/users'} component={AdminUsers} />
            <Route exact path={'/auth/login'} component={Login} />
            <Route exact path={'/auth/register'} component={Register} />
            <Route exact path={'/admin/devices'} component={AdminDevices} />
            <Route
              exact
              path={'/admin/devices/edit/:id'}
              component={UpdateDevice}
            />
            <Route
              exact
              path={'/admin/categories/edit/:id'}
              component={UpdateCategory}
            />
            <Route
              exact
              path={'/admin/types/edit/:id'}
              component={UpdateType}
            />

            <Route
              exact
              path={'/admin/devices/delete/:id'}
              component={DeleteDevice}
            />
            <Route
              exact
              path={'/admin/categories/delete/:id'}
              component={DeleteCategory}
            />
            <Route
              exact
              path={'/admin/types/delete/:id'}
              component={DeleteType}
            />
            <Route exact path={'/admin/instock'} component={DevicesInStock} />
            <Route exact path={'/admin/admins'} component={Administrators} />
            <Route exact path={'/admin/superadmins'} component={Superadmins} />
            <Route exact path={'/admin/students'} component={Students} />
            <Route exact path={'/admin/lecturers'} component={Lecturers} />
            <Route exact path={'/admin/types'} component={Types} />
            <Route exact path={'/admin/categories'} component={Categories} />
            <Route
              exact
              path={'/admin/checkedout'}
              component={DevicesCheckedOut}
            />
          </Switch>
        </HashRouter>
      </div>
    </UserProvider>
  );
}

export default App;
