import tw from 'tailwind-styled-components';
import FooterIcons from './FooterIcons';
import FooterInfo from './FooterInfo';
import Container from '../Container';

const SFooterTop = tw.footer`
  border-t border-gray-200
  pt-12
  pb-12
`;

const SFooterFlexBox = tw.div`
  md:flex
  md:justify-between
`;

interface Props {}

const Footer = (props: Props) => {
  return (
    <>
      <Container>
        <SFooterTop>
          <SFooterFlexBox>
            <FooterInfo />
            <FooterIcons />
          </SFooterFlexBox>
        </SFooterTop>
      </Container>
    </>
  );
};

export default Footer;
