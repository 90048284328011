import AdminLayout from "../../../layouts/AdminLayout";
import Datagrid from "../../../components/layout/Admin/Datagrid";
import columns from "./typeColumns";
import Loader from "react-loader-spinner";
import { useQuery } from "@apollo/client";
import * as queries from "../../../queries";
import AddType from "./AddType";
import { Alert, Snackbar } from "@mui/material";

interface Props {}

const styles = {
  loader: "fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/3",
};

const Types = (props: Props) => {
  const { loading, error, data } = useQuery(queries.TYPES);
  let rows;
  if (!loading && !error) {
    rows = data.types.map((type: any) => {
      return {
        id: type.id,
        name: type.name,
        description: type.description,
      };
    });
  }
  return (
    <AdminLayout>
      <AddType />
      {error && <div>Error! {error.message}</div>}
      {loading && (
        <div className={styles.loader}>
          <Loader type={"ThreeDots"} width={80} color="#3730A3" />
        </div>
      )}
      {error && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={true}
          autoHideDuration={3000}
        >
          <Alert severity="error">
            <p>
              <span className="font-bold">An error has occured:</span> The data
              could not be loaded.
            </p>
          </Alert>
        </Snackbar>
      )}
      {data && !error && <Datagrid rows={rows} columns={columns} />}
    </AdminLayout>
  );
};

export default Types;
