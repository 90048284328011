import AdminLayout from "../../../layouts/AdminLayout";
import Datagrid from "../../../components/layout/Admin/Datagrid";
import columns from "./deviceColumns";
import AddDevice from "./AddDevice";
import Loader from "react-loader-spinner";
import { useQuery } from "@apollo/client";
import * as queries from "../../../queries";
import { Alert, Snackbar } from "@mui/material";

interface Props {}

const styles = {
  flex: "flex items-center justify-between",
  loader: "fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/3",
};

const AdminDevices = (props: Props) => {
  let rows;
  const { loading, error, data } = useQuery(queries.DEVICES);

  if (!loading && !error) {
    rows = data.devices.map((device: any) => {
      const date = new Date(device.date_created).toLocaleDateString();
      const isAvailable = device.is_available ? "In Stock" : "Checked Out";

      return {
        id: device.id,
        name: device.name,
        description: device.description,
        category: device.category.name,
        status: isAvailable,
        specifications: device.specifications,
        type: device.type.name,
        date_added: date,
      };
    });
  }

  return (
    <AdminLayout>
      <AddDevice />
      {loading && (
        <div className={styles.loader}>
          <Loader type={"ThreeDots"} width={80} color="#3730A3" />
        </div>
      )}
      {error && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={true}
          autoHideDuration={3000}
        >
          <Alert severity="error">
            <p><span className="font-bold">An error has occured:</span> The data could not be loaded.</p>
          </Alert>
        </Snackbar>
      )}
      {data && !error && <Datagrid rows={rows} columns={columns} />}
    </AdminLayout>
  );
};

export default AdminDevices;
