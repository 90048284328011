type ContainerProps = {
  children: React.ReactNode;
  $fullWidth?: boolean;
};

const styles = {
  base: "mx-auto px-4 sm:px-6 sm-max-w-screen-sm md:max-w-screen-2xl",
};

const Container = ({ children, $fullWidth }: ContainerProps) => {
  return (
    <div className={`${styles.base} ${$fullWidth ? "w-full" : ""}`}>
      {children}
    </div>
  );
};

export default Container;
