import { Formik, Field } from "formik";
import Button from "../Button";
import InputError from "../FormComponents/InputError";
import * as yup from "yup";
import tw from "tailwind-styled-components";
import { useUser } from "../../../contexts/AuthenticatedContext";
import { useHistory } from "react-router-dom";
import SelectError from "../FormComponents/SelectError";
// import AlternativeSignUp from './AlternativeSignUp';

const validationSchema = yup.object({
  firstname: yup.string().required("Please enter your first name").max(128),
  lastname: yup.string().required("Please enter your last name").max(128),
  email: yup.string().required("Please enter your last name").max(128).email(),
  password: yup.string().required("Please enter your password"),
});

interface Props {}

const SForm = tw.form`
   bg-white p-4 rounded-lg shadow-lg grid gap-y-4 max-w-lg mx-auto

`;

const SubmitButton = tw.div`
  text-center 
`;

const Register = (props: Props) => {
  const handleUserContext = useUser();
  const history = useHistory();

  const handleLoginContext = ({ email, id }: { email: string; id: number }) => {
    handleUserContext!.dispatch({
      type: "setUser",
      payload: { email: email, id: id },
    });
    return history.push("/");
  };

  return (
    <div>
      <Formik
        initialValues={{
          firstname: "",
          lastname: "",
          email: "",
          password: "",
          roleId: 1,
        }}
        onSubmit={async (data, { setSubmitting }) => {
          setSubmitting(true);

          // TODO: change to path defined in .env or config file
          const request = await fetch(
            "https://belgian-waffles-server.herokuapp.com/register",
            {
              method: "POST",
              credentials: "include",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
          );
          const response = await request.json();

          if (response.statusCode === 401) {
            // TODO: Handle error code (unauthorized request == wrong password/username combination);
            return;
          }

          handleLoginContext(response);

          setSubmitting(false);
        }}
        validationSchema={validationSchema}
      >
        {({ values, isSubmitting, handleBlur, handleSubmit, handleChange }) => (
          <SForm onSubmit={handleSubmit}>
            <Field
              type="text"
              as={InputError}
              name="firstname"
              placeholder="First Name"
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <Field
              type="text"
              as={InputError}
              name="lastname"
              placeholder="Last Name"
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <Field
              type="email"
              as={InputError}
              name="email"
              placeholder="E-mail Address"
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <Field
              type="password"
              as={InputError}
              name="password"
              placeholder="Password"
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <Field
              as={SelectError}
              name="roleId"
              placeholder="Role"
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <SubmitButton>
              <Button
                type="submit"
                buttonType="primary"
                disabled={isSubmitting}
              >
                Register
              </Button>
            </SubmitButton>
          </SForm>
        )}
      </Formik>
      {/* <AlternativeSignUp /> */}
    </div>
  );
};

export default Register;
