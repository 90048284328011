import React from 'react';
import { useField } from 'formik';
import Select, { InputProps } from './Select';
import tw from 'tailwind-styled-components';

const Error = tw.div`
  text-red-500
  font-bold
  text-xl
`;

const InputError = (props: InputProps) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : '';
  return (
    <React.Fragment>
      <Select {...field} placeholder={props.placeholder} />
      {errorText && <Error>{errorText}</Error>}
    </React.Fragment>
  );
};

export default InputError;
