import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Loader from 'react-loader-spinner';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import AdminLayout from '../../../layouts/AdminLayout';
import * as queries from '../../../queries';
import { Formik } from 'formik';

interface Props {}

type DetailParams = {
  id?: string | undefined;
};

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description is required'),
  category: yup.string().required('Category is required'),
  type: yup.string().required('Type is required'),
  specs: yup.string().required('Specifications are required'),
});

const styles = {
  loader: 'fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/3',
};

const AddDevice = (props: Props) => {
  const { id } = useParams<DetailParams>();

  const { loading: getDevicesLoading, data: getDevicesData } = useQuery(
    queries.GET_DEVICE,
    {
      variables: { id: Number(id) },
    }
  );

  const { loading: usersLoading, data: usersData } = useQuery(queries.USERS);

  const { loading: categoriesLoading, data: categoriesData } = useQuery(
    queries.CATEGORIES
  );

  const { loading: typesLoading, data: typesData } = useQuery(queries.TYPES);

  const [addDevice] = useMutation(queries.UPDATE_DEVICE);

  const history = useHistory();

  return (
    <AdminLayout>
      {getDevicesData && usersData && categoriesData && typesData && (
        <Container maxWidth='xl'>
          <Typography variant='h3' my={'2rem'}>
            Update Device
          </Typography>
          <Box
            sx={{
              border: '1px solid #bdbdbd',
              padding: '2rem',
              borderRadius: '10px',
              boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
            }}
          >
            <div>
              <Formik
                initialValues={{
                  // Als ik hier bijv. bij type getDevicesData.getDevice.type.id wil gebruiken, geeft hij nog steeds gans het object mee - wat ben ik verkeerd aan het doen?
                  name: getDevicesData.getDevice.name,
                  description: getDevicesData.getDevice.description,
                  category: getDevicesData.getDevice.category.id,
                  type: getDevicesData.getDevice.type.id,
                  user: getDevicesData.getDevice.user.id,
                  status: getDevicesData.getDevice.is_available,
                  specs: getDevicesData.getDevice.specifications.toString(),
                }}
                onSubmit={async (data, { setSubmitting }) => {
                  setSubmitting(true);

                  addDevice({
                    variables: {
                      id: Number(id),
                      name: data.name,
                      typeId: parseInt(data.type),
                      is_available: data.status,
                      description: data.description,
                      userId: data.user,
                      categoryId: parseInt(data.category),
                      qr_code: 'this is a qr code',
                      specifications: data.specs,
                    },
                    refetchQueries: [
                      {
                        query: queries.DEVICES,
                      },
                    ],
                  });

                  history.push('/admin/devices');

                  setSubmitting(false);
                }}
                validationSchema={validationSchema}
              >
                {({
                  values,
                  isSubmitting,
                  handleBlur,
                  handleSubmit,
                  handleChange,
                  touched,
                  errors,
                }) => (
                  <form
                    className='grid gap-y-4'
                    id='updateDevice'
                    onSubmit={handleSubmit}
                  >
                    <FormControl>
                      <TextField
                        fullWidth
                        id='name'
                        name='name'
                        label='Name'
                        value={values.name}
                        onChange={handleChange}
                        error={Boolean(errors.name)}
                        helperText={errors.name}
                      />
                    </FormControl>
                    <FormControl>
                      <InputLabel id='user'>User</InputLabel>
                      <Select
                        labelId='user'
                        id='user'
                        name='user'
                        defaultValue={values.user}
                        label='User'
                        onChange={handleChange}
                      >
                        {usersData &&
                          usersData.users.map(
                            (user: {
                              id: number;
                              firstname: string;
                              lastname: string;
                            }) => (
                              <MenuItem key={user.id} value={user.id}>
                                {`${user.firstname} ${user.lastname}`}
                              </MenuItem>
                            )
                          )}
                      </Select>
                      <FormHelperText sx={{ color: '#f44336' }}>
                        {touched.user && errors.user}
                      </FormHelperText>
                    </FormControl>
                    <FormControl>
                      <InputLabel id='category'>Category</InputLabel>
                      <Select
                        labelId='category'
                        id='category'
                        name='category'
                        defaultValue={values.category}
                        label='Category'
                        onChange={handleChange}
                      >
                        {categoriesData &&
                          categoriesData.categories.map(
                            (category: { name: string; id: number }) => (
                              <MenuItem key={category.id} value={category.id}>
                                {`${category.name}`}
                              </MenuItem>
                            )
                          )}
                      </Select>
                      <FormHelperText sx={{ color: '#f44336' }}>
                        {touched.category && errors.category}
                      </FormHelperText>
                    </FormControl>
                    <FormControl>
                      <InputLabel id='type'>Type</InputLabel>
                      <Select
                        labelId='type'
                        id='type'
                        defaultValue={values.type}
                        name='type'
                        label='Type'
                        onChange={handleChange}
                      >
                        {typesData &&
                          typesData.types.map(
                            (type: { name: string; id: number }) => (
                              <MenuItem key={type.id} value={type.id}>
                                {`${type.name}`}
                              </MenuItem>
                            )
                          )}
                      </Select>
                      <FormHelperText sx={{ color: '#f44336' }}>
                        {touched.type && errors.type}
                      </FormHelperText>
                    </FormControl>
                    <TextField
                      fullWidth
                      id='specs'
                      name='specs'
                      type='text'
                      label='specifications'
                      value={values.specs.toString()}
                      onChange={handleChange}
                      error={Boolean(errors.specs)}
                      helperText={errors.specs}
                    />

                    <TextField
                      multiline={true}
                      rows={3}
                      fullWidth
                      id='description'
                      name='description'
                      label='description'
                      value={values.description}
                      onChange={handleChange}
                      error={Boolean(errors.description)}
                      helperText={errors.description}
                    />
                    <FormGroup>
                      <FormControlLabel
                        id='status'
                        control={<Checkbox value={values.status} />}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        name='status'
                        label='Is in stock'
                      />
                    </FormGroup>
                    <Button
                      disabled={isSubmitting}
                      variant={'contained'}
                      type='submit'
                      name='updateDevice'
                      onClick={() => console.log(typeof values.status)}
                    >
                      Add Device
                    </Button>
                  </form>
                )}
              </Formik>
            </div>
          </Box>
        </Container>
      )}
      {getDevicesLoading && typesLoading && usersLoading && categoriesLoading && (
        <div className={styles.loader}>
          <Loader type={'ThreeDots'} width={80} color='#3730A3' />
        </div>
      )}
    </AdminLayout>
  );
};

export default AddDevice;
