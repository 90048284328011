import React from 'react';
import BaseLayout from '../layouts/Base';
import Container from '../components/layout/Container';
import AllDevices from '../components/layout/Devices/AllDevices';

interface Props {}

const Devices = (props: Props) => {
  return (
    <BaseLayout
      title='Devices'
      subtitle='Here you can find all the devices available in our inventory for use.'
      hasHero={true}
      backgroundImage='https://i.pinimg.com/originals/b8/4f/41/b84f4195a6d33c93d01111287c0c7b7e.jpg'
    >
      <Container>
        <AllDevices />
      </Container>
    </BaseLayout>
  );
};

export default Devices;
