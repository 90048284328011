import AdminLayout from "../../../layouts/AdminLayout";
import Datagrid from "../../../components/layout/Admin/Datagrid";
import columns from "./userColumns";
import Loader from "react-loader-spinner";
import { useQuery } from "@apollo/client";
import * as queries from "../../../queries";

interface Props {}

const styles = {
  loader: "fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/3",
};

const Students = (props: Props) => {
  const { loading, error, data } = useQuery(queries.STUDENTS);
  let rows;
  if (!loading) {
    rows = data.getByRole.map((user: any) => {
      let role = "Student";

      return {
        id: user.id,
        firstname: user.firstname,
        lastname: user.lastname,
        role: role,
        email: user.email,
      };
    });
  }

  return (
    <AdminLayout>
      {error && <div>Error! {error.message}</div>}
      {loading && (
        <div className={styles.loader}>
          <Loader type={"ThreeDots"} width={80} color="#3730A3" />
        </div>
      )}
      {data && <Datagrid rows={rows} columns={columns} />}
    </AdminLayout>
  );
};

export default Students;
