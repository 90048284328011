import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import AdminLayout from '../../../layouts/AdminLayout';
import { Box, Container, Typography } from '@mui/material';
import * as queries from '../../../queries';
import { Redirect, useParams } from 'react-router-dom';

interface Props {}

type Params = {
  id: string | undefined;
};

const DeleteCategory = (props: Props) => {
  const { id } = useParams<Params>();

  const [deleteCategory, { loading }] = useMutation(queries.DELETE_CATEGORY, {
    refetchQueries: [
      {
        query: queries.CATEGORIES,
      },
    ],
  });

  useEffect(() => {
    deleteCategory({
      variables: {
        id: Number(id),
      },
    });
  }, [deleteCategory, id]);

  return (
    <AdminLayout>
      <Container maxWidth='xl'>
        <Typography variant='h3' sx={{ my: '1rem' }}>
          Add Device
        </Typography>
        <Box
          sx={{
            border: '1px solid #bdbdbd',
            padding: '2rem',
            borderRadius: '10px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
          }}
        >
          {loading ? (
            <p>Loading...</p>
          ) : (
            <Redirect
              to={{
                pathname: '/admin/categories',
              }}
            />
          )}
        </Box>
      </Container>
    </AdminLayout>
  );
};

export default DeleteCategory;
