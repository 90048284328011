import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";

const columns = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "name",
    headerName: "Name",
    width: 180,
  },
  {
    field: "description",
    headerName: "Description",
    width: 500,
  },
  {
    field: "actions",
    headerName: "Actions",
    renderCell: (cellValues) => {
      return (
        <Link to={`/admin/categories/edit/${cellValues.row.id}`}>
          <Button>
            <EditIcon />
          </Button>
        </Link>
      );
    },
    width: 150,
  },
];

export default columns;
