import AdminLayout from "../../../layouts/AdminLayout";
import Datagrid from "../../../components/layout/Admin/Datagrid";
import Loader from "react-loader-spinner";
import columns from "./deviceColumns";
import AddDevice from "./AddDevice";
import { useQuery } from "@apollo/client";
import * as queries from "../../../queries";

interface Props {}

const styles = {
  loader: "fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/3",
};

const DevicesInStock = (props: Props) => {
  const { loading, error, data } = useQuery(queries.INSTOCK_DEVICES);
  let rows;

  if (!loading) {
    rows = data.deviceStatus.map((device: any) => {
      const date = new Date(device.date_created).toLocaleDateString();
      const status = "In Stock";

      return {
        id: device.id,
        name: device.name,
        description: device.description,
        category: device.category.name,
        status: status,
        type: device.type.name,
        date_added: date,
      };
    });
  }

  return (
    <AdminLayout>
      <AddDevice />
      {error && <div>Error! {error.message}</div>}
      {loading && (
        <div className={styles.loader}>
          <Loader type={"ThreeDots"} width={80} color="#3730A3" />
        </div>
      )}
      {data && <Datagrid rows={rows} columns={columns} />}
    </AdminLayout>
  );
};

export default DevicesInStock;
