import BaseLayout from "../layouts/Base";
import Container from "../components/layout/Container";
import Categories from "../components/layout/Home/Categories";
import RecentAdded from "../components/layout/Home/RecentAdded";

type device = {
  id: Number;
  specifications: String[];
};

const Home = (props: device) => {
  return (
    <BaseLayout
      title="Inventory of Artevelde"
      subtitle="Making technology accessible for you."
      hasHero={true}
      backgroundImage="https://i.pinimg.com/originals/b8/4f/41/b84f4195a6d33c93d01111287c0c7b7e.jpg"
    >
      <Categories />
      <Container>
        <RecentAdded />
      </Container>
    </BaseLayout>
  );
};

export default Home;
