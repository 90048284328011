import { useState } from "react";
import SearchField from "../../../components/layout/SearchField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Formik } from "formik";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import * as queries from "../../../queries";
import { TextField, Button } from "@mui/material";

interface Props {}

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  description: yup.string().required("Description is required"),
});

const styles = {
  flex: "flex items-center justify-between",
};

const AddCategory = (props: Props) => {
  const [open, setOpen] = useState(false);

  const [addDevice] = useMutation(queries.ADD_CATEGORY);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={styles.flex}>
      <SearchField />
      <Button variant="contained" onClick={handleClickOpen}>
        Add Category
      </Button>
      <Dialog open={open} onClose={handleClose} keepMounted>
        <DialogTitle>Add Category</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To add a new category, please fill out the form below.
          </DialogContentText>
          <div>
            <Formik
              initialValues={{
                name: "",
                description: "",
                icon_url: "http://lorempixel.com/640/480",
              }}
              onSubmit={async (data, { setSubmitting }) => {
                setSubmitting(true);

                addDevice({
                  variables: {
                    name: data.name,
                    description: data.description,
                    icon_url: "http://lorempixel.com/640/480",
                  },
                  refetchQueries: [
                    {
                      query: queries.CATEGORIES,
                    },
                  ],
                });

                handleClose();

                setSubmitting(false);
              }}
              validationSchema={validationSchema}
            >
              {({
                values,
                isSubmitting,
                handleBlur,
                handleSubmit,
                errors,
                touched,
                handleChange,
              }) => (
                <form className="mt-4 grid gap-y-4" onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    label="Name"
                    value={values.name}
                    onChange={handleChange}
                    error={Boolean(errors.name)}
                    helperText={errors.name}
                  />
                  <TextField
                    multiline={true}
                    rows={3}
                    fullWidth
                    id="description"
                    name="description"
                    label="description"
                    value={values.description}
                    onChange={handleChange}
                    error={Boolean(errors.description)}
                    helperText={errors.description}
                  />
                  <DialogActions>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Add Category
                    </Button>
                  </DialogActions>
                </form>
              )}
            </Formik>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AddCategory;
