import React from "react";
import AdminLayout from "../../../layouts/AdminLayout";
import Datagrid from "../../../components/layout/Admin/Datagrid";
import columns from "./userColumns";
import Loader from "react-loader-spinner";
import { useQuery } from "@apollo/client";
import * as queries from "../../../queries";
import { Alert, Snackbar } from "@mui/material";

interface Props {}

const styles = {
  loader: "fixed top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/3",
};

const AdminUsers = (props: Props) => {
  const { loading, error, data } = useQuery(queries.USERS);
  let rows;
  if (!loading && !error) {
    rows = data.users.map((user: any) => {
      let role;
      switch (user.role.id) {
        case 1:
          role = "Super Admin";
          break;
        case 2:
          role = "Admin";
          break;
        case 3:
          role = "Student";
          break;
        case 4:
          role = "Lecturer";
          break;
      }

      return {
        id: user.id,
        firstname: user.firstname,
        lastname: user.lastname,
        role: role,
        email: user.email,
      };
    });
  }
  return (
    <AdminLayout>
      {error && <div>Error! {error.message}</div>}
      {loading && (
        <div className={styles.loader}>
          <Loader type={"ThreeDots"} width={80} color="#3730A3" />
        </div>
      )}
      {error && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={true}
          autoHideDuration={3000}
        >
          <Alert severity="error">
            <p>
              <span className="font-bold">An error has occured:</span> The data
              could not be loaded.
            </p>
          </Alert>
        </Snackbar>
      )}
      {data && !error && <Datagrid rows={rows} columns={columns} />}
    </AdminLayout>
  );
};

export default AdminUsers;
